.upload--button{
	@extend a.btn--primary2;
	border-radius:3px;
	color:#212121;
	cursor: pointer;
	margin:0;
	letter-spacing:0.24px;
}
.upload--placeholder{letter-spacing:0.24px;}
.upload--file{
	visibility: hidden;
	position: absolute;
	&+ .upload--button{
		margin: 0 8px 0 0;
	}
}
.upload--tip{
	color:rgba(0,0,0,.5);
	line-height: 14px;
	margin-top: 4px;
	display: block;
}

.upload--img{
    width: 42px;
    height: 42px;
    overflow: hidden;
    position: relative;
    border: 1px solid #e6e8ec;
    input[type="file"]{
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    label{
        width: inherit;
        height: inherit;
        position: relative;
        background-image: url(#{$pathImages}/ico/ico-upload.svg);
        background-color: #fff;
        background-repeat:no-repeat;
        background-position: center;
        cursor: pointer;
        transition: all .2s;
        &:hover{
            box-shadow: inset 0 0 10px #c7ccd5;
        }
    }
    img{
        height: 100%;
        width: auto;
        position: absolute;
        &.portrait{
            height: auto;
            width: 100%;
        }
    }
}
.upload--img--remove{
	position: absolute;
	background-size: 12px;
	width: 14px;
	height: 14px;
	color: #fff;
	font-size: 10px;
	display: flex;
	justify-content: center;
	right: 1px;
	bottom: 1px;
	border-radius: 100%;
	background-color: #DE6060;
	border: 1px solid #DE6060;
	&:hover{
		background-color: darken(#DE6060, 10%);
	}
	&:before{
		content:'\00D7';
	}
}

.upload--img--multiple{
    background-color: #F6F8F9;
    padding: 10px;
    display: inline-block;
    small {
        color:rgba(0,0,0,0.5);
    }
    &.single__image{
        .form__photo__uploader__li{
            width: initial !important;
            &:first-child{
                &:before{
                    left: 45% !important;
                }
            }
            .form__photo__group{
                .upload__img{
                    width: 80px !important;
                    height: 80px !important;
                }
            }
        }
        .form__photo__placeholder{
            width: 102px;
            height: 125px;
        }
    }
    ul {
        margin: 10px 0;
        display: flex;
        position: relative;
        li {
            padding: 10px;
            box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.10), 0px 0px 2px 0px rgba(0,0,0,0.16);
            background-color: #fff;
            margin-right: 10px;
            position: relative !important;
            top: 0px !important;
            &:first-child{
            	&:before{
            		content: "";
            		position: absolute;
            		width: 0;
            		height: 0;
            		margin-left: -8px;
            		top:: 0;
            		left: 50px;
            		box-sizing: border-box;
            		border: 5px solid #000;
            		border-color: #fff #fff transparent transparent;
            		-webkit-transform-origin: 0 0;
            		transform-origin: 0 0;
            		-webkit-transform: rotate(-45deg);
            		transform: rotate(-45deg);
            		box-shadow: 1px -1px 0 0 rgba(0,0,0,.2);
                }
            }
            &:last-child{
                margin-right: 0px;
            }
            .handle {
                position: relative;
                cursor: move;
                display: inline-block;
                svg{
                    transform: rotate(90deg);
                    margin-left: 5px;
                }
            }
            .img--multiple__group {
                display: flex;
                justify-content: space-between;
                .upload--img--card{
                	margin-right: 6px;
                	&:last-child{margin:0;}
                }
                .upload--img {
                    width: 60px;
                    height: 60px;
                    &:hover{
                        .preview--show{
                            opacity: 1;
                            visibility: visible;
                        }
                        .upload--img--remove{
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                    .preview--show{
                        opacity: 0;
                        visibility: hidden;
                        position: absolute;
                        background: url('../images/ico/ico-see-preview.svg') center no-repeat;
                        background-size: 9px;
                        width: 14px;
                        height: 14px;
                        color: #fff;
                        font-size: 10px;
                        display: -ms-flexbox;
                        display: flex;
                        -ms-flex-pack: center;
                        justify-content: center;
                        right: 0;
                        bottom: 1px;
                        left: 0;
                        margin: auto;
                        border-radius: 100%;
                        background-color: #44C7F4;
                        border: 1px solid #44C7F4;
                        transition: all .3s;
                        &:hover{
                            background-color: darken(#44C7F4, 10%);
                        }
                    }
                    .upload--img--remove{
                        opacity: 0;
                        visibility: hidden;
                        transition: all .3s;
                    }
                }
                .img--multiple__title {
                    display: block;
                    font-size: 10px;
                    text-align: center;
                    border: 1px solid #e6e8ec;
                    margin-top: 3px;
                    padding: 2px 0;
                }
            }
            .img--card__remove {
            	@extend .upload--img--remove;
                position: absolute;
                top: -7px;
                right: -7px;
                width: 16px;
                height: 16px;
                font-size: 14px;
                text-align: center;
                line-height: 15px;
                display: flex;
                align-items: baseline;
                justify-content: center;
                &:hover{
                    background-color: darken(#DE6060, 10%);
                }
                &:before{
                	position: absolute;
                	top: -1px;
                }
            }
            .form__photo__remove__server{
                position: absolute;
                top: -7px;
                right: -7px;
                width: 16px;
                height: 16px;
                font-size: 14px;
                border-radius: 100%;
                text-align: center;
                line-height: 13px;
                color: #fff;
                background-color: #DE6060;
                &:hover{
                    background-color: darken(#DE6060, 10%);
                }
            }
        }
    }
    .img--multiple__placeholder{
        width: 135px;
        height: 128px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed;
        border-radius: 5px;
        flex-direction: column;
        margin: 10px 0 10px 10px;
        &:hover{
            i{
                top: 0px;
            }
            span{
                bottom: -15px;
                opacity: 1;
            }
        }
        i{
            font-style: normal;
            font-size: 38px;
            display: block;
            line-height: 0;
            position: relative;
            top: 10px;
            transition: all .4s ease-in-out;
        }
        span{
            font-size: 15px;
            display: block;
            position: relative;
            bottom: 0px;
            opacity: 0;
            transition: all .4s ease-in-out;
        }
    }
}

.preview--overlay{
    position: fixed;
    z-index: 99;
    display: none;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, .5);
}
.preview--popup {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: 0;
    position: absolute;
    .preview--popup--inner {
        position: absolute;
        width: 600px;
        height: auto;
        padding: 10px;
        background-color: #fff;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1), 0px 0px 2px 0px rgba(0, 0, 0, 0.16);
        .preview--close{
            position: absolute;
            background-size: 12px;
            width: 24px;
            height: 24px;
            color: #fff;
            font-size: 16px;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;
            right: -11px;
            top: -11px;
            border-radius: 100%;
            background-color: #DE6060;
            border: 1px solid #DE6060;
            &:hover{
                background-color: darken(#DE6060, 10%);
            }
        }
        img.preview--image{
            width: auto;
            max-width: 100%;
            height: auto;
            display: block;
            line-height: 0;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            /* padding: 40px 0; */
            margin: 0 auto;
        }
    }
}
// Add Class when popup
.preview{
    .main--sidebar{
        z-index: 10;
    }
    .main--header{
        position: relative;
        z-index: 0;
    }
    .main--content{
        position: relative;
        z-index: 11;
    }
}