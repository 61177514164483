.check{
    display:none;
    &:disabled{
        &+ label{
            cursor:default;
            &:before{}
            &:after{
                background-color:#E6E6E6;
            }
        }
    }
    &+ label{
        cursor:pointer;
        position: relative;
        display: inline-block;
        font-size: 11px;
        margin:8px 0;
        padding:0 0 0 20px;
        font-weight: 400;
        &:before{}
        &:after{
            content: '';
            display: inline-block;
            vertical-align: sub;
            border: 1px solid $check-border;
            background-color: $check-bg;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 1px;
            left:0;
            z-index: 1;
            text-align: center;
        }
    }
    &:checked,
    &.checked{
        &+ label{
            &:before{
                position: absolute;
                content: "";
                margin: auto;
                background: $check-icon;
                background-size: 15px;
                width: 15px;
                height: 13px;
                top: -1px;
                left: 3px;
                z-index: 3;
            }
            &:after{}
        }
    }
}
.checkbox__two-layers{
    background-color:#fff;
    border:1px solid #D6D6D6;
    .check{
        &+ label{
            margin:0;
            padding:8px 10px 7px 30px;
            border-bottom:1px solid #D6D6D6;
            display: block;
            font-size: 12px;
            &:before{}
            &:after{margin: 8px 10px 7px;}
        }
        &:checked,
        &.checked{
            &+ label{
                &:before{margin: 8px 10px 7px;}
                &:after{}
            }
        }
    }
}


.radio{
    display:none;
    &:disabled{
        &+ label{
            cursor:default;
            &:before{}
            &:after{
                background-color:#E6E6E6;
            }
        }
    }
    &+ label{
        cursor:pointer;
        position: relative;
        display: inline-block;
        font-size: 11px;
        margin:8px 0;
        padding:0 0 0 20px;
        font-weight: 400;
        &:before{
            content: "";
            position: absolute;
            width: 9px;
            height: 9px;
            z-index: 0;
            margin: auto;
            top: 3px;
            left: 3px;
            border-radius:100%;
            transform:scale(0);
            transition: all .2s ease;
        }
        &:after{
            content: '';
            display: inline-block;
            vertical-align: sub;
            border: 1px solid $radio-border;
            background-color: $radio-bg;
            width: 15px;
            height: 15px;
            border-radius:100%;
            position: absolute;
            top: 0px;
            left:0;
            z-index: 1;
            text-align: center;
        }
    }
    &:checked,
    &.checked{
        &+ label{
            &:before{
                background-color: $radio-selected;
                z-index: 3;
                transform:scale(1);
            }
            &:after{}
        }
    }
}


/* MOVE CHECK STYLE */
.checkbox--item-select{
    display: flex;
    width: 100%;
    .checkbox__wrapper {
        display: flex;
        align-items: center;
        height: 35px;
        margin-left: 10px;
        label{
            transition: all .2s;
            &:hover{
                color: $check-hover;
            }
        }
    }
    .item-selected__body{
        .checkbox__wrapper {
            label{
                white-space: nowrap;
                width: calc(100% - 48px);
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    .item-list__body{
        ul{
            &.disabled{
                .form--checkbox__wrapper {
                    .checkbox--label{
                        color: #aaa;
                        pointer-events: none;
                        &:before{
                            border-color: #bbb;
                            background-color: #ddd;
                        }
                    }
                }
            }
        }
    }
}
.item-selected{
    width: 230px;
    background-color: #F8F8F8;
    .item-selected__header{
        padding: 10px;
        border-bottom: 1px solid rgba(97, 127, 143, 0.3);
        h6{font-size: 12px;font-weight:bold;}
    }
    .item-selected__body{
        position: relative;
        z-index: 0;
    }
    ul{
        min-height: 160px;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        margin:0;
        li{
            position: relative !important;
            top: 0px !important;
            animation: slideFadeIn .5s;
            .checkbox__wrapper{
                label{line-height: 16px;}
                &:hover{
                    &:after{
                        cursor: pointer;
                    }
                }
            }
            .handle{
                position: absolute;
                right:8px;
                top:0;
                bottom:0;
                margin: auto;
                width: 14px;
                height: 2px;
                display: block;
                background-color: #9B9B9B;
                cursor: move;
                &:before{
                    content:'';
                    position:absolute;
                    bottom:0;
                    margin: auto;
                    width: 14px;
                    height: 2px;
                    display: block;
                    background-color: #9B9B9B;

                    top: -10px;
                }
                &:after{
                    content:'';
                    position:absolute;
                    top:0;
                    margin: auto;
                    width: 14px;
                    height: 2px;
                    display: block;
                    background-color: #9B9B9B;

                    bottom: -11px;
                }
            }
        }
    }
    .item-selected__placeholder{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;
        width: 100%;
        z-index: 0;
        min-height: 160px;
        svg{
            path{fill: $check-placeholder-icon-color;}
        }
        p{
            font-size: 13px;
            width: 80%;
            margin: 10px auto;
            opacity: 0.3;
            color: $check-placeholder-color;
            use-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            cursor: default;
        }
    }
}
.item-list{
    width: calc(100% - 230px);
    margin: 0px 0px 0px 15px;
    border: 1px solid #F4F2EF;
    background-color: #fff;
    .item-list__header{
        display: flex;
        justify-content: space-between;
        padding: 9px 10px 8px 10px;
        border: 1px solid #F3F4F6;
        h6{font-size: 12px;font-weight:bold;}
    }
    .item-list__body{
        border: 1px solid #F3F4F6;
        border-top: none;
        ul{
            min-height: 162px;
            margin:0;
            li{
                animation: fadeIn .5s;
                display: inline-block;
                width: 220px;
            }
        }
    }
    .checkbox--label{
        color: #9B9B9B;
    }
}

.toggle-transition{
    transition: all .3s;
    overflow: hidden;
    height: 40px;
}

.toggle-enter, .toggle-leave{
    height: auto;
}
/* END MOVE CHECK STYLE */