// COLOR
.text-white{color:#fff;}
.text-orange{color:#f48d0f;}

.text-error{color:$text-error;}

.uppercase{text-transform: uppercase;}

// FONT
.light{ font-family: $font-base-light; }
.thin{ font-weight: $font-base-thin; }
.medium{ font-weight: $font-base-medium; }
.semibold{ font-family: $font-base-semibold; }
.bold{ font-family: $font-base-bold; }
.extrabold{ font-family: $font-base-extrabold; }


body {
    font-family: $font-base;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-style: normal;
    font-variant: normal;
    font-weight: 400;
    color: $text-color;
    letter-spacing:0;
}
h1 {
    font-size: 42px;
    font-weight:normal;
    margin:0 0 15px 0;
    &.s38{ font-size:38px; line-height:37px; }
    &.s36{ font-size:36px; }
}
h2{ 
    font-size: 32px;
    color:#212121;
    font-weight:normal;
    margin:0 0 20px 0;
}
h3 {
    font-size: 28px;
    font-weight:400;
    margin:0 0 5px 0;
}
h4 {
    font-size: 20px;
    font-weight:400;
    margin:0 0 12px 0;
}
h5 {
    font-size: 18px;    
    font-weight:normal;
    margin:0 0 10px 0;
}
h6{
    font-size: 15px;
    font-weight:normal;    
    margin:0 0 10px 0;
    line-height:17px;
    &.s14{ font-size:14px; }
    &.s13{ font-size:13px; }
    &.s12{ font-size:12px; }
}
// h1,h2,h3,
// h4,h5,h6{
//     display: inline-block;
// }
p{
    color:#000000;
    font-size: 14px;
    // line-height: 24px;
}
small {
    font-size: 12px;
    // line-height: 24px;
    &.s8{ font-size: 8px; }
    &.s9{ font-size: 9px; }
    &.s10{ font-size: 10px; }
    &.s11{ font-size: 11px; }    
}
label{
    font-size: 11px;
    small{ padding: 2px 0 0 0; }
    &.s9{font-size: 9px;}
}
a {
    color:inherit;
    font-size: inherit;
    text-decoration: none;    
    &.link{
        color: $href-link;
        &:hover{ text-decoration:underline; }      
    }
    small{
        color:inherit;
        line-height:0px; padding: 5px 0;
    }
}
hr{
    display: inline-block;
    width: 100%;
    border:none;
    border-bottom:1px solid #E6E6E6;
}
ol{
    padding-left:15px;
}
ul{
    list-style:none;
    margin:1em 0;
    padding:0;   
    &.disc{
        list-style-type: disc;
        padding: 0 0 0 18px;
        li{
            line-height: 18px;
            margin: 5px 0;
        }
    }
    &.dash{
        li{
            &:before{
                content:'-';
                margin-right: 5px;
            }
        }
    }
    &.alpha{
        @extend ul.disc;
        list-style-type: upper-alpha;
    }
    li{
        a{ 
            display: block;
            text-decoration: none;
            img{
                float:left;
            }
        }
    }
}

