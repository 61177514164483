input[type='submit'],
button{ cursor:pointer; }
a,
input[type='submit'],
button{
	position:relative;
	display: inline-block;
	border: none; outline: none; 
	text-decoration:none;
	font-size:11px;
	@extend .medium;
	&.btn--primary{
		@extend .transition;
		padding:7px 15px;
		background: $btn-primary-bg;
		color:$btn-primary-color;
		border:1px solid $btn-primary-border;
		&:hover{
			background: $btn-primary-bg-hover;
		}
	}
	&.btn--primary2{
		@extend .btn--primary;
		background: $btn-primary2-bg;
		color: $btn-primary2-color;
		border:1px solid $btn-primary2-border;
		&:hover{
			background: $btn-primary2-bg-hover;
		}
        &.transparent{
            background-color: transparent;
        }
	} 
    &.mini{
        padding: 3px 8px 2px;
        font-size: 10px;
    }
    &.disabled{
        opacity:0.5;
        pointer-events:none;
    }
}

// button switch style
.switch{
	position: relative;
	display: block;
	vertical-align: top;
	width: 32px;
	height: 20px;
	border-radius: 18px;
    margin-bottom:0 !important;
    .switch-input {
    	position: absolute;
    	top: 0;
    	left: 0;
    	opacity: 0;
    }
    .switch-label {
    	position: relative;
    	display: block;
    	height: inherit;
        cursor: pointer;
    	font-size: 12px;
    	background: $btn-switch-bg;
    	border-radius: inherit;
    	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
    }
    .switch-label:before, .switch-label:after {
    	position: absolute;
    	top: 50%;
    	margin-top: -.5em;
    	line-height: 1;
    	-webkit-transition: inherit;
    	-moz-transition: inherit;
    	-o-transition: inherit;
    	transition: inherit;
    }
    .switch-input:checked ~ .switch-label {
    	background: $btn-switch-bg-active;
    	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
    }
    .switch-input:checked ~ .switch-label:before {
    	opacity: 0;
    }
    .switch-input:checked ~ .switch-label:after {
    	opacity: 1;
    }
    .switch-input:disabled ~ .switch-label{
        background: $btn-switch-bg-disable !important;
        opacity: .5;
        pointer-events:none;
    }
    .switch-input:disabled ~ .switch-handle{
        opacity: .5;
        pointer-events:none;
    }
    .switch-handle {
    	position: absolute;
    	top: 2px;
    	right: 14px;
    	width: 16px;
    	height: 16px;
        cursor: pointer;
    	background: linear-gradient(to bottom, #FFFFFF 40%, #f0f0f0);
    	background-image: -webkit-linear-gradient(top, #FFFFFF 40%, #f0f0f0);
    	border-radius: 100%;
    	box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.3);
    }
    .switch-input:checked ~ .switch-handle {
    	right: 2px;
    	box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
    }

    .switch-label, .switch-handle {
    	transition: All 0.3s ease;
    	-webkit-transition: All 0.3s ease;
    	-moz-transition: All 0.3s ease;
    	-o-transition: All 0.3s ease;
    }
}
.switch-line{
	@extend .switch;
	width: 12px;
	height: 2px;
	.switch-input{visibility:hidden;}
	.switch-label{
		box-shadow:none;
		border: 1px solid $btn-switch2-border;
	}
	.switch-handle{
		width: 10px;
    	height: 10px;
    	top: -4px;
    	right: 7px;
    	border: 1px solid #AEAEAE;
    	box-shadow: 0px 1px 2px 0 rgba(0, 0, 0, 0.18);
	}
	.switch-input:checked ~ .switch-handle {
    	right: -4px;
        border: 2px solid $btn-switch2-border-active;
    }
    .switch-input:checked ~ .switch-label {
        border-color: $btn-switch2-border-active;
    }
}