    *,
*:before,
*:after {
    box-sizing: border-box;
}
// a, a:active, a:focus, input, p, article {outline: none;}
iframe{border: none;}
html,body{
    min-height: 100%;
    margin:0;
    padding: 0;
    background-color: $body-bg; //background color in body
    overflow-x: hidden;
    overflow-y: hidden;
    &.bg--white,
    &.bg-lightgrey{
        .main--content__desc{color: #000;}
    }
}
img, video, picture {
    display:inherit;
    max-width: 100%;
    height:auto;
    outline: none;
}
img[src=''], 
img:not([src]){ opacity:0; }
.noflicker{ 
    backface-visibility:hidden; 
    transform:translateZ(0) translate3d(0,0,0);
    transform-style: preserve-3d;
}
a{
    i~small{ display:inline-block; margin-top:0; }
}
.blur{ filter:blur(5px); }
.clearfix{ 
    &:before,
    &:after{
        display: table;
        line-height: 0;
        content: "";
    }
    &:after { clear: both; }
}
.d-block{display:block !important;}
.d-inline-block{display:inline-block;}
.hide{display: none;}
.show{display: block;}
.flex {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    &.vcenter{
        align-items: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
    }
    &.vstart{
        align-items: flex-start;
    }
    &.vend{
        align-items: flex-end;
    }
    &.flow{ flex-flow: row wrap; }

    .field{margin-bottom:0;}
}

.left {
    justify-content: flex-start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
}
.right {
    justify-content: flex-end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
}
.center {
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
}
.around {
    justify-content: space-around;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
}
.between {
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
} 

.margin{
    &-x5{ margin-left:5px;margin-right:5px !important;}
    &-x10{ margin-left:10px;margin-right:10px !important;}
    
    &-t5{ margin-top:5px !important;}
    &-t10{ margin-top:10px !important;}
    &-t15{ margin-top:15px !important;}
    &-t20{ margin-top:20px !important;}

    &-r5{ margin-right:5px !important;}
    &-r10{ margin-right:10px !important;}
    &-r15{ margin-right:15px !important;}
    &-r20{ margin-right:20px !important;}

    &-b5{ margin-bottom:5px !important;}
    &-b10{ margin-bottom:10px !important;}
    &-b15{ margin-bottom:15px !important;}
    &-b20{ margin-bottom:20px !important;}

    &-l5{ margin-left:5px !important;}
    &-l10{ margin-left:10px !important;}
    &-l15{ margin-left:15px !important;}
    &-l20{ margin-left:20px !important;}
}

.left-align{text-align:left;}
.center-align{text-align:center;}
.right-align{text-align:right;}
.float-left{ float:left; }
.float-right{ float:right; }
.nowrap { white-space:nowrap; }
.margin0{ margin:0 !important; }
.padding0{ padding:0 !important; }
.spacing0{ letter-spacing:0; }
.border{ border:1px solid #e1e1e1; }
.noborder{border:0px solid transparent !important;}
.border-bottom{ border-bottom:1px solid #eaeaea; }

.width-initial{width:initial;}
.width-auto{width:auto !important;} 
.widthfull{ width:100% !important; }
.width200{width:200px !important; }
.width150{width:150px !important; }
.width100{width:100px !important; }
.width50{width:50px !important; }

.row{
    margin-bottom:0;
    display: flex;
    flex-wrap: wrap;
}

.bg--white{ background:white; }
.bg--gray{ background:rgba(243,238,253,.4); }
.bg-lightgrey{ background-color: #f7f7f7; }

.more-link{}
.more-desc{ overflow:hidden; }
.transition{transition: all .3s ease;}

.overflow-visible{overflow:visible !important;}
.link-trigger-content, .expand-content{display: none;}
.bg__overlay{
    position:relative;
    &:before{
        content:'';
        position: absolute;
        width: 100%;
        height: 100%;
        background-color:rgba(0,0,0,.5);
        z-index: 5;
        top:0;left:0;
        transition: .3s ease-out;
    }
}

.main--wrapper{
    transition: left 0.3s ease;
    width: calc(100% - 220px);
    position: relative;
    left: 220px;
    transition: all .3s ease;
    &.animate{
        left:0px;
        width: 100%;
    }
}
.main--content{
    padding: 24px 15px 26px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 50px);
    backface-visibility: hidden;
    transform: translateZ(0);
    .main--content-inner{
        padding: 28px 25px 0;
    }
}
.main--content__title{
    @extend .bold;
    color: $main-title;
    line-height: 33px;
    margin: 0 0 3px;
}
.main--content__desc{
    color:#fff;
    letter-spacing: 0.33px;
    line-height: 14px;
}
.main--logo{
    height:141px;
}
.user__img{
    border-radius: 100%;
    background: inherit;
    // margin: auto;
}
/* Vue js */
[v-cloak] {
  display: none;
}

// responsive
// @include break(1280) {    
// }

// @include break(1024) {
// }

// @include break(600) {  
// }

// @include break(480) {
// }