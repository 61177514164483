// Variables
// --------------------------

$fa-font-path:        "/fonts" !default;
//$fa-font-path:        "//netdna.bootstrapcdn.com/font-awesome/4.0.3/fonts" !default; // for referencing Bootstrap CDN font files directly
$fa-css-prefix:       fa !default;
$fa-version:          "4.0.3" !default;
$fa-border-color:     #eee !default;
$fa-inverse:          #fff !default;
$fa-li-width:        (30em / 14) !default;

$fa-var-glass: "\f000";
$fa-var-music: "\f001";
$fa-var-search: "\f002";
$fa-var-envelope-o: "\f003";
$fa-var-heart: "\f004";
$fa-var-star: "\f005";
$fa-var-star-o: "\f006";
$fa-var-user: "\f007";
$fa-var-film: "\f008";
$fa-var-th-large: "\f009";
$fa-var-th: "\f00a";
$fa-var-th-list: "\f00b";
$fa-var-check: "\f00c";
$fa-var-times: "\f00d";
$fa-var-search-plus: "\f00e";
$fa-var-search-minus: "\f010";
$fa-var-power-off: "\f011";
$fa-var-signal: "\f012";
$fa-var-cog: "\f013";
$fa-var-trash-o: "\f014";
$fa-var-home: "\f015";
$fa-var-file-o: "\f016";
$fa-var-clock-o: "\f017";
$fa-var-road: "\f018";
$fa-var-download: "\f019";
$fa-var-arrow-circle-o-down: "\f01a";
$fa-var-arrow-circle-o-up: "\f01b";
$fa-var-inbox: "\f01c";
$fa-var-play-circle-o: "\f01d";
$fa-var-repeat: "\f01e";
$fa-var-refresh: "\f021";
$fa-var-list-alt: "\f022";
$fa-var-lock: "\f023";
$fa-var-flag: "\f024";
$fa-var-headphones: "\f025";
$fa-var-volume-off: "\f026";
$fa-var-volume-down: "\f027";
$fa-var-volume-up: "\f028";
$fa-var-qrcode: "\f029";
$fa-var-barcode: "\f02a";
$fa-var-tag: "\f02b";
$fa-var-tags: "\f02c";
$fa-var-book: "\f02d";
$fa-var-bookmark: "\f02e";
$fa-var-print: "\f02f";
$fa-var-camera: "\f030";
$fa-var-font: "\f031";
$fa-var-bold: "\f032";
$fa-var-italic: "\f033";
$fa-var-text-height: "\f034";
$fa-var-text-width: "\f035";
$fa-var-align-left: "\f036";
$fa-var-align-center: "\f037";
$fa-var-align-right: "\f038";
$fa-var-align-justify: "\f039";
$fa-var-list: "\f03a";
$fa-var-outdent: "\f03b";
$fa-var-indent: "\f03c";
$fa-var-video-camera: "\f03d";
$fa-var-picture-o: "\f03e";
$fa-var-pencil: "\f040";
$fa-var-map-marker: "\f041";
$fa-var-adjust: "\f042";
$fa-var-tint: "\f043";
$fa-var-pencil-square-o: "\f044";
$fa-var-share-square-o: "\f045";
$fa-var-check-square-o: "\f046";
$fa-var-arrows: "\f047";
$fa-var-step-backward: "\f048";
$fa-var-fast-backward: "\f049";
$fa-var-backward: "\f04a";
$fa-var-play: "\f04b";
$fa-var-pause: "\f04c";
$fa-var-stop: "\f04d";
$fa-var-forward: "\f04e";
$fa-var-fast-forward: "\f050";
$fa-var-step-forward: "\f051";
$fa-var-eject: "\f052";
$fa-var-chevron-left: "\f053";
$fa-var-chevron-right: "\f054";
$fa-var-plus-circle: "\f055";
$fa-var-minus-circle: "\f056";
$fa-var-times-circle: "\f057";
$fa-var-check-circle: "\f058";
$fa-var-question-circle: "\f059";
$fa-var-info-circle: "\f05a";
$fa-var-crosshairs: "\f05b";
$fa-var-times-circle-o: "\f05c";
$fa-var-check-circle-o: "\f05d";
$fa-var-ban: "\f05e";
$fa-var-arrow-left: "\f060";
$fa-var-arrow-right: "\f061";
$fa-var-arrow-up: "\f062";
$fa-var-arrow-down: "\f063";
$fa-var-share: "\f064";
$fa-var-expand: "\f065";
$fa-var-compress: "\f066";
$fa-var-plus: "\f067";
$fa-var-minus: "\f068";
$fa-var-asterisk: "\f069";
$fa-var-exclamation-circle: "\f06a";
$fa-var-gift: "\f06b";
$fa-var-leaf: "\f06c";
$fa-var-fire: "\f06d";
$fa-var-eye: "\f06e";
$fa-var-eye-slash: "\f070";
$fa-var-exclamation-triangle: "\f071";
$fa-var-plane: "\f072";
$fa-var-calendar: "\f073";
$fa-var-random: "\f074";
$fa-var-comment: "\f075";
$fa-var-magnet: "\f076";
$fa-var-chevron-up: "\f077";
$fa-var-chevron-down: "\f078";
$fa-var-retweet: "\f079";
$fa-var-shopping-cart: "\f07a";
$fa-var-folder: "\f07b";
$fa-var-folder-open: "\f07c";
$fa-var-arrows-v: "\f07d";
$fa-var-arrows-h: "\f07e";
$fa-var-bar-chart-o: "\f080";
$fa-var-twitter-square: "\f081";
$fa-var-facebook-square: "\f082";
$fa-var-camera-retro: "\f083";
$fa-var-key: "\f084";
$fa-var-cogs: "\f085";
$fa-var-comments: "\f086";
$fa-var-thumbs-o-up: "\f087";
$fa-var-thumbs-o-down: "\f088";
$fa-var-star-half: "\f089";
$fa-var-heart-o: "\f08a";
$fa-var-sign-out: "\f08b";
$fa-var-linkedin-square: "\f08c";
$fa-var-thumb-tack: "\f08d";
$fa-var-external-link: "\f08e";
$fa-var-sign-in: "\f090";
$fa-var-trophy: "\f091";
$fa-var-github-square: "\f092";
$fa-var-upload: "\f093";
$fa-var-lemon-o: "\f094";
$fa-var-phone: "\f095";
$fa-var-square-o: "\f096";
$fa-var-bookmark-o: "\f097";
$fa-var-phone-square: "\f098";
$fa-var-twitter: "\f099";
$fa-var-facebook: "\f09a";
$fa-var-github: "\f09b";
$fa-var-unlock: "\f09c";
$fa-var-credit-card: "\f09d";
$fa-var-rss: "\f09e";
$fa-var-hdd-o: "\f0a0";
$fa-var-bullhorn: "\f0a1";
$fa-var-bell: "\f0f3";
$fa-var-certificate: "\f0a3";
$fa-var-hand-o-right: "\f0a4";
$fa-var-hand-o-left: "\f0a5";
$fa-var-hand-o-up: "\f0a6";
$fa-var-hand-o-down: "\f0a7";
$fa-var-arrow-circle-left: "\f0a8";
$fa-var-arrow-circle-right: "\f0a9";
$fa-var-arrow-circle-up: "\f0aa";
$fa-var-arrow-circle-down: "\f0ab";
$fa-var-globe: "\f0ac";
$fa-var-wrench: "\f0ad";
$fa-var-tasks: "\f0ae";
$fa-var-filter: "\f0b0";
$fa-var-briefcase: "\f0b1";
$fa-var-arrows-alt: "\f0b2";
$fa-var-users: "\f0c0";
$fa-var-link: "\f0c1";
$fa-var-cloud: "\f0c2";
$fa-var-flask: "\f0c3";
$fa-var-scissors: "\f0c4";
$fa-var-files-o: "\f0c5";
$fa-var-paperclip: "\f0c6";
$fa-var-floppy-o: "\f0c7";
$fa-var-square: "\f0c8";
$fa-var-bars: "\f0c9";
$fa-var-list-ul: "\f0ca";
$fa-var-list-ol: "\f0cb";
$fa-var-strikethrough: "\f0cc";
$fa-var-underline: "\f0cd";
$fa-var-table: "\f0ce";
$fa-var-magic: "\f0d0";
$fa-var-truck: "\f0d1";
$fa-var-pinterest: "\f0d2";
$fa-var-pinterest-square: "\f0d3";
$fa-var-google-plus-square: "\f0d4";
$fa-var-google-plus: "\f0d5";
$fa-var-money: "\f0d6";
$fa-var-caret-down: "\f0d7";
$fa-var-caret-up: "\f0d8";
$fa-var-caret-left: "\f0d9";
$fa-var-caret-right: "\f0da";
$fa-var-columns: "\f0db";
$fa-var-sort: "\f0dc";
$fa-var-sort-asc: "\f0dd";
$fa-var-sort-desc: "\f0de";
$fa-var-envelope: "\f0e0";
$fa-var-linkedin: "\f0e1";
$fa-var-undo: "\f0e2";
$fa-var-gavel: "\f0e3";
$fa-var-tachometer: "\f0e4";
$fa-var-comment-o: "\f0e5";
$fa-var-comments-o: "\f0e6";
$fa-var-bolt: "\f0e7";
$fa-var-sitemap: "\f0e8";
$fa-var-umbrella: "\f0e9";
$fa-var-clipboard: "\f0ea";
$fa-var-lightbulb-o: "\f0eb";
$fa-var-exchange: "\f0ec";
$fa-var-cloud-download: "\f0ed";
$fa-var-cloud-upload: "\f0ee";
$fa-var-user-md: "\f0f0";
$fa-var-stethoscope: "\f0f1";
$fa-var-suitcase: "\f0f2";
$fa-var-bell-o: "\f0a2";
$fa-var-coffee: "\f0f4";
$fa-var-cutlery: "\f0f5";
$fa-var-file-text-o: "\f0f6";
$fa-var-building-o: "\f0f7";
$fa-var-hospital-o: "\f0f8";
$fa-var-ambulance: "\f0f9";
$fa-var-medkit: "\f0fa";
$fa-var-fighter-jet: "\f0fb";
$fa-var-beer: "\f0fc";
$fa-var-h-square: "\f0fd";
$fa-var-plus-square: "\f0fe";
$fa-var-angle-double-left: "\f100";
$fa-var-angle-double-right: "\f101";
$fa-var-angle-double-up: "\f102";
$fa-var-angle-double-down: "\f103";
$fa-var-angle-left: "\f104";
$fa-var-angle-right: "\f105";
$fa-var-angle-up: "\f106";
$fa-var-angle-down: "\f107";
$fa-var-desktop: "\f108";
$fa-var-laptop: "\f109";
$fa-var-tablet: "\f10a";
$fa-var-mobile: "\f10b";
$fa-var-circle-o: "\f10c";
$fa-var-quote-left: "\f10d";
$fa-var-quote-right: "\f10e";
$fa-var-spinner: "\f110";
$fa-var-circle: "\f111";
$fa-var-reply: "\f112";
$fa-var-github-alt: "\f113";
$fa-var-folder-o: "\f114";
$fa-var-folder-open-o: "\f115";
$fa-var-smile-o: "\f118";
$fa-var-frown-o: "\f119";
$fa-var-meh-o: "\f11a";
$fa-var-gamepad: "\f11b";
$fa-var-keyboard-o: "\f11c";
$fa-var-flag-o: "\f11d";
$fa-var-flag-checkered: "\f11e";
$fa-var-terminal: "\f120";
$fa-var-code: "\f121";
$fa-var-reply-all: "\f122";
$fa-var-mail-reply-all: "\f122";
$fa-var-star-half-o: "\f123";
$fa-var-location-arrow: "\f124";
$fa-var-crop: "\f125";
$fa-var-code-fork: "\f126";
$fa-var-chain-broken: "\f127";
$fa-var-question: "\f128";
$fa-var-info: "\f129";
$fa-var-exclamation: "\f12a";
$fa-var-superscript: "\f12b";
$fa-var-subscript: "\f12c";
$fa-var-eraser: "\f12d";
$fa-var-puzzle-piece: "\f12e";
$fa-var-microphone: "\f130";
$fa-var-microphone-slash: "\f131";
$fa-var-shield: "\f132";
$fa-var-calendar-o: "\f133";
$fa-var-fire-extinguisher: "\f134";
$fa-var-rocket: "\f135";
$fa-var-maxcdn: "\f136";
$fa-var-chevron-circle-left: "\f137";
$fa-var-chevron-circle-right: "\f138";
$fa-var-chevron-circle-up: "\f139";
$fa-var-chevron-circle-down: "\f13a";
$fa-var-html5: "\f13b";
$fa-var-css3: "\f13c";
$fa-var-anchor: "\f13d";
$fa-var-unlock-alt: "\f13e";
$fa-var-bullseye: "\f140";
$fa-var-ellipsis-h: "\f141";
$fa-var-ellipsis-v: "\f142";
$fa-var-rss-square: "\f143";
$fa-var-play-circle: "\f144";
$fa-var-ticket: "\f145";
$fa-var-minus-square: "\f146";
$fa-var-minus-square-o: "\f147";
$fa-var-level-up: "\f148";
$fa-var-level-down: "\f149";
$fa-var-check-square: "\f14a";
$fa-var-pencil-square: "\f14b";
$fa-var-external-link-square: "\f14c";
$fa-var-share-square: "\f14d";
$fa-var-compass: "\f14e";
$fa-var-caret-square-o-down: "\f150";
$fa-var-caret-square-o-up: "\f151";
$fa-var-caret-square-o-right: "\f152";
$fa-var-eur: "\f153";
$fa-var-gbp: "\f154";
$fa-var-usd: "\f155";
$fa-var-inr: "\f156";
$fa-var-jpy: "\f157";
$fa-var-rub: "\f158";
$fa-var-krw: "\f159";
$fa-var-btc: "\f15a";
$fa-var-file: "\f15b";
$fa-var-file-text: "\f15c";
$fa-var-sort-alpha-asc: "\f15d";
$fa-var-sort-alpha-desc: "\f15e";
$fa-var-sort-amount-asc: "\f160";
$fa-var-sort-amount-desc: "\f161";
$fa-var-sort-numeric-asc: "\f162";
$fa-var-sort-numeric-desc: "\f163";
$fa-var-thumbs-up: "\f164";
$fa-var-thumbs-down: "\f165";
$fa-var-youtube-square: "\f166";
$fa-var-youtube: "\f167";
$fa-var-xing: "\f168";
$fa-var-xing-square: "\f169";
$fa-var-youtube-play: "\f16a";
$fa-var-dropbox: "\f16b";
$fa-var-stack-overflow: "\f16c";
$fa-var-instagram: "\f16d";
$fa-var-flickr: "\f16e";
$fa-var-adn: "\f170";
$fa-var-bitbucket: "\f171";
$fa-var-bitbucket-square: "\f172";
$fa-var-tumblr: "\f173";
$fa-var-tumblr-square: "\f174";
$fa-var-long-arrow-down: "\f175";
$fa-var-long-arrow-up: "\f176";
$fa-var-long-arrow-left: "\f177";
$fa-var-long-arrow-right: "\f178";
$fa-var-apple: "\f179";
$fa-var-windows: "\f17a";
$fa-var-android: "\f17b";
$fa-var-linux: "\f17c";
$fa-var-dribbble: "\f17d";
$fa-var-skype: "\f17e";
$fa-var-foursquare: "\f180";
$fa-var-trello: "\f181";
$fa-var-female: "\f182";
$fa-var-male: "\f183";
$fa-var-gittip: "\f184";
$fa-var-sun-o: "\f185";
$fa-var-moon-o: "\f186";
$fa-var-archive: "\f187";
$fa-var-bug: "\f188";
$fa-var-vk: "\f189";
$fa-var-weibo: "\f18a";
$fa-var-renren: "\f18b";
$fa-var-pagelines: "\f18c";
$fa-var-stack-exchange: "\f18d";
$fa-var-arrow-circle-o-right: "\f18e";
$fa-var-arrow-circle-o-left: "\f190";
$fa-var-caret-square-o-left: "\f191";
$fa-var-dot-circle-o: "\f192";
$fa-var-wheelchair: "\f193";
$fa-var-vimeo-square: "\f194";
$fa-var-try: "\f195";
$fa-var-plus-square-o: "\f196";

// Mixins
// --------------------------

@mixin fa-icon-rotate($degrees, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$rotation);
  -webkit-transform: rotate($degrees);
     -moz-transform: rotate($degrees);
      -ms-transform: rotate($degrees);
       -o-transform: rotate($degrees);
          transform: rotate($degrees);
}

@mixin fa-icon-flip($horiz, $vert, $rotation) {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=$rotation);
  -webkit-transform: scale($horiz, $vert);
     -moz-transform: scale($horiz, $vert);
      -ms-transform: scale($horiz, $vert);
       -o-transform: scale($horiz, $vert);
          transform: scale($horiz, $vert);
}
/* FONT PATH
 * -------------------------- */

@font-face {
  font-family: 'FontAwesome';
  src: url('#{$fa-font-path}/fontawesome/fontawesome-webfont.eot?v=#{$fa-version}');
  src: url('#{$fa-font-path}/fontawesome/fontawesome-webfont.eot?#iefix&v=#{$fa-version}') format('embedded-opentype'),
    url('#{$fa-font-path}/fontawesome/fontawesome-webfont.woff?v=#{$fa-version}') format('woff'),
    url('#{$fa-font-path}/fontawesome/fontawesome-webfont.ttf?v=#{$fa-version}') format('truetype'),
    url('#{$fa-font-path}/fontawesome/fontawesome-webfont.svg?v=#{$fa-version}#fontawesomeregular') format('svg');
  //src: url('#{$fa-font-path}/FontAwesome.otf') format('opentype'); // used when developing fonts
  font-weight: normal;
  font-style: normal;
}
// Base Class Definition
// -------------------------

.#{$fa-css-prefix} {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// Icon Sizes
// -------------------------

/* makes the font 33% larger relative to the icon container */
.#{$fa-css-prefix}-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}
.#{$fa-css-prefix}-2x { font-size: 2em; }
.#{$fa-css-prefix}-3x { font-size: 3em; }
.#{$fa-css-prefix}-4x { font-size: 4em; }
.#{$fa-css-prefix}-5x { font-size: 5em; }
// Fixed Width Icons
// -------------------------
.#{$fa-css-prefix}-fw {
  width: (18em / 14);
  text-align: center;
}
// List Icons
// -------------------------

.#{$fa-css-prefix}-ul {
  padding-left: 0;
  margin-left: $fa-li-width;
  list-style-type: none;
  > li { position: relative; }
}
.#{$fa-css-prefix}-li {
  position: absolute;
  left: -$fa-li-width;
  width: $fa-li-width;
  top: (2em / 14);
  text-align: center;
  &.#{$fa-css-prefix}-lg {
    left: -$fa-li-width + (4em / 14);
  }
}
// Bordered & Pulled
// -------------------------

.#{$fa-css-prefix}-border {
  padding: .2em .25em .15em;
  border: solid .08em $fa-border-color;
  border-radius: .1em;
}

.pull-right { float: right; }
.pull-left { float: left; }

.#{$fa-css-prefix} {
  &.pull-left { margin-right: .3em; }
  &.pull-right { margin-left: .3em; }
}
// Spinning Icons
// --------------------------

.#{$fa-css-prefix}-spin {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@-moz-keyframes spin {
  0% { -moz-transform: rotate(0deg); }
  100% { -moz-transform: rotate(359deg); }
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(359deg); }
}
@-o-keyframes spin {
  0% { -o-transform: rotate(0deg); }
  100% { -o-transform: rotate(359deg); }
}
@-ms-keyframes spin {
  0% { -ms-transform: rotate(0deg); }
  100% { -ms-transform: rotate(359deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
// Rotated & Flipped Icons
// -------------------------

.#{$fa-css-prefix}-rotate-90  { @include fa-icon-rotate(90deg, 1);  }
.#{$fa-css-prefix}-rotate-180 { @include fa-icon-rotate(180deg, 2); }
.#{$fa-css-prefix}-rotate-270 { @include fa-icon-rotate(270deg, 3); }

.#{$fa-css-prefix}-flip-horizontal { @include fa-icon-flip(-1, 1, 0); }
.#{$fa-css-prefix}-flip-vertical   { @include fa-icon-flip(1, -1, 2); }
// Stacked Icons
// -------------------------

.#{$fa-css-prefix}-stack {
  position: relative;
  display: inline-block;
  width: 2em;
  height: 2em;
  line-height: 2em;
  vertical-align: middle;
}
.#{$fa-css-prefix}-stack-1x, .#{$fa-css-prefix}-stack-2x {
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
}
.#{$fa-css-prefix}-stack-1x { line-height: inherit; }
.#{$fa-css-prefix}-stack-2x { font-size: 2em; }
.#{$fa-css-prefix}-inverse { color: $fa-inverse; }
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */

.#{$fa-css-prefix}-glass:before { content: $fa-var-glass; }
.#{$fa-css-prefix}-music:before { content: $fa-var-music; }
.#{$fa-css-prefix}-search:before { content: $fa-var-search; }
.#{$fa-css-prefix}-envelope-o:before { content: $fa-var-envelope-o; }
.#{$fa-css-prefix}-heart:before { content: $fa-var-heart; }
.#{$fa-css-prefix}-star:before { content: $fa-var-star; }
.#{$fa-css-prefix}-star-o:before { content: $fa-var-star-o; }
.#{$fa-css-prefix}-user:before { content: $fa-var-user; }
.#{$fa-css-prefix}-film:before { content: $fa-var-film; }
.#{$fa-css-prefix}-th-large:before { content: $fa-var-th-large; }
.#{$fa-css-prefix}-th:before { content: $fa-var-th; }
.#{$fa-css-prefix}-th-list:before { content: $fa-var-th-list; }
.#{$fa-css-prefix}-check:before { content: $fa-var-check; }
.#{$fa-css-prefix}-times:before { content: $fa-var-times; }
.#{$fa-css-prefix}-search-plus:before { content: $fa-var-search-plus; }
.#{$fa-css-prefix}-search-minus:before { content: $fa-var-search-minus; }
.#{$fa-css-prefix}-power-off:before { content: $fa-var-power-off; }
.#{$fa-css-prefix}-signal:before { content: $fa-var-signal; }
.#{$fa-css-prefix}-gear:before,
.#{$fa-css-prefix}-cog:before { content: $fa-var-cog; }
.#{$fa-css-prefix}-trash-o:before { content: $fa-var-trash-o; }
.#{$fa-css-prefix}-home:before { content: $fa-var-home; }
.#{$fa-css-prefix}-file-o:before { content: $fa-var-file-o; }
.#{$fa-css-prefix}-clock-o:before { content: $fa-var-clock-o; }
.#{$fa-css-prefix}-road:before { content: $fa-var-road; }
.#{$fa-css-prefix}-download:before { content: $fa-var-download; }
.#{$fa-css-prefix}-arrow-circle-o-down:before { content: $fa-var-arrow-circle-o-down; }
.#{$fa-css-prefix}-arrow-circle-o-up:before { content: $fa-var-arrow-circle-o-up; }
.#{$fa-css-prefix}-inbox:before { content: $fa-var-inbox; }
.#{$fa-css-prefix}-play-circle-o:before { content: $fa-var-play-circle-o; }
.#{$fa-css-prefix}-rotate-right:before,
.#{$fa-css-prefix}-repeat:before { content: $fa-var-repeat; }
.#{$fa-css-prefix}-refresh:before { content: $fa-var-refresh; }
.#{$fa-css-prefix}-list-alt:before { content: $fa-var-list-alt; }
.#{$fa-css-prefix}-lock:before { content: $fa-var-lock; }
.#{$fa-css-prefix}-flag:before { content: $fa-var-flag; }
.#{$fa-css-prefix}-headphones:before { content: $fa-var-headphones; }
.#{$fa-css-prefix}-volume-off:before { content: $fa-var-volume-off; }
.#{$fa-css-prefix}-volume-down:before { content: $fa-var-volume-down; }
.#{$fa-css-prefix}-volume-up:before { content: $fa-var-volume-up; }
.#{$fa-css-prefix}-qrcode:before { content: $fa-var-qrcode; }
.#{$fa-css-prefix}-barcode:before { content: $fa-var-barcode; }
.#{$fa-css-prefix}-tag:before { content: $fa-var-tag; }
.#{$fa-css-prefix}-tags:before { content: $fa-var-tags; }
.#{$fa-css-prefix}-book:before { content: $fa-var-book; }
.#{$fa-css-prefix}-bookmark:before { content: $fa-var-bookmark; }
.#{$fa-css-prefix}-print:before { content: $fa-var-print; }
.#{$fa-css-prefix}-camera:before { content: $fa-var-camera; }
.#{$fa-css-prefix}-font:before { content: $fa-var-font; }
.#{$fa-css-prefix}-bold:before { content: $fa-var-bold; }
.#{$fa-css-prefix}-italic:before { content: $fa-var-italic; }
.#{$fa-css-prefix}-text-height:before { content: $fa-var-text-height; }
.#{$fa-css-prefix}-text-width:before { content: $fa-var-text-width; }
.#{$fa-css-prefix}-align-left:before { content: $fa-var-align-left; }
.#{$fa-css-prefix}-align-center:before { content: $fa-var-align-center; }
.#{$fa-css-prefix}-align-right:before { content: $fa-var-align-right; }
.#{$fa-css-prefix}-align-justify:before { content: $fa-var-align-justify; }
.#{$fa-css-prefix}-list:before { content: $fa-var-list; }
.#{$fa-css-prefix}-dedent:before,
.#{$fa-css-prefix}-outdent:before { content: $fa-var-outdent; }
.#{$fa-css-prefix}-indent:before { content: $fa-var-indent; }
.#{$fa-css-prefix}-video-camera:before { content: $fa-var-video-camera; }
.#{$fa-css-prefix}-picture-o:before { content: $fa-var-picture-o; }
.#{$fa-css-prefix}-pencil:before { content: $fa-var-pencil; }
.#{$fa-css-prefix}-map-marker:before { content: $fa-var-map-marker; }
.#{$fa-css-prefix}-adjust:before { content: $fa-var-adjust; }
.#{$fa-css-prefix}-tint:before { content: $fa-var-tint; }
.#{$fa-css-prefix}-edit:before,
.#{$fa-css-prefix}-pencil-square-o:before { content: $fa-var-pencil-square-o; }
.#{$fa-css-prefix}-share-square-o:before { content: $fa-var-share-square-o; }
.#{$fa-css-prefix}-check-square-o:before { content: $fa-var-check-square-o; }
.#{$fa-css-prefix}-arrows:before { content: $fa-var-arrows; }
.#{$fa-css-prefix}-step-backward:before { content: $fa-var-step-backward; }
.#{$fa-css-prefix}-fast-backward:before { content: $fa-var-fast-backward; }
.#{$fa-css-prefix}-backward:before { content: $fa-var-backward; }
.#{$fa-css-prefix}-play:before { content: $fa-var-play; }
.#{$fa-css-prefix}-pause:before { content: $fa-var-pause; }
.#{$fa-css-prefix}-stop:before { content: $fa-var-stop; }
.#{$fa-css-prefix}-forward:before { content: $fa-var-forward; }
.#{$fa-css-prefix}-fast-forward:before { content: $fa-var-fast-forward; }
.#{$fa-css-prefix}-step-forward:before { content: $fa-var-step-forward; }
.#{$fa-css-prefix}-eject:before { content: $fa-var-eject; }
.#{$fa-css-prefix}-chevron-left:before { content: $fa-var-chevron-left; }
.#{$fa-css-prefix}-chevron-right:before { content: $fa-var-chevron-right; }
.#{$fa-css-prefix}-plus-circle:before { content: $fa-var-plus-circle; }
.#{$fa-css-prefix}-minus-circle:before { content: $fa-var-minus-circle; }
.#{$fa-css-prefix}-times-circle:before { content: $fa-var-times-circle; }
.#{$fa-css-prefix}-check-circle:before { content: $fa-var-check-circle; }
.#{$fa-css-prefix}-question-circle:before { content: $fa-var-question-circle; }
.#{$fa-css-prefix}-info-circle:before { content: $fa-var-info-circle; }
.#{$fa-css-prefix}-crosshairs:before { content: $fa-var-crosshairs; }
.#{$fa-css-prefix}-times-circle-o:before { content: $fa-var-times-circle-o; }
.#{$fa-css-prefix}-check-circle-o:before { content: $fa-var-check-circle-o; }
.#{$fa-css-prefix}-ban:before { content: $fa-var-ban; }
.#{$fa-css-prefix}-arrow-left:before { content: $fa-var-arrow-left; }
.#{$fa-css-prefix}-arrow-right:before { content: $fa-var-arrow-right; }
.#{$fa-css-prefix}-arrow-up:before { content: $fa-var-arrow-up; }
.#{$fa-css-prefix}-arrow-down:before { content: $fa-var-arrow-down; }
.#{$fa-css-prefix}-mail-forward:before,
.#{$fa-css-prefix}-share:before { content: $fa-var-share; }
.#{$fa-css-prefix}-expand:before { content: $fa-var-expand; }
.#{$fa-css-prefix}-compress:before { content: $fa-var-compress; }
.#{$fa-css-prefix}-plus:before { content: $fa-var-plus; }
.#{$fa-css-prefix}-minus:before { content: $fa-var-minus; }
.#{$fa-css-prefix}-asterisk:before { content: $fa-var-asterisk; }
.#{$fa-css-prefix}-exclamation-circle:before { content: $fa-var-exclamation-circle; }
.#{$fa-css-prefix}-gift:before { content: $fa-var-gift; }
.#{$fa-css-prefix}-leaf:before { content: $fa-var-leaf; }
.#{$fa-css-prefix}-fire:before { content: $fa-var-fire; }
.#{$fa-css-prefix}-eye:before { content: $fa-var-eye; }
.#{$fa-css-prefix}-eye-slash:before { content: $fa-var-eye-slash; }
.#{$fa-css-prefix}-warning:before,
.#{$fa-css-prefix}-exclamation-triangle:before { content: $fa-var-exclamation-triangle; }
.#{$fa-css-prefix}-plane:before { content: $fa-var-plane; }
.#{$fa-css-prefix}-calendar:before { content: $fa-var-calendar; }
.#{$fa-css-prefix}-random:before { content: $fa-var-random; }
.#{$fa-css-prefix}-comment:before { content: $fa-var-comment; }
.#{$fa-css-prefix}-magnet:before { content: $fa-var-magnet; }
.#{$fa-css-prefix}-chevron-up:before { content: $fa-var-chevron-up; }
.#{$fa-css-prefix}-chevron-down:before { content: $fa-var-chevron-down; }
.#{$fa-css-prefix}-retweet:before { content: $fa-var-retweet; }
.#{$fa-css-prefix}-shopping-cart:before { content: $fa-var-shopping-cart; }
.#{$fa-css-prefix}-folder:before { content: $fa-var-folder; }
.#{$fa-css-prefix}-folder-open:before { content: $fa-var-folder-open; }
.#{$fa-css-prefix}-arrows-v:before { content: $fa-var-arrows-v; }
.#{$fa-css-prefix}-arrows-h:before { content: $fa-var-arrows-h; }
.#{$fa-css-prefix}-bar-chart-o:before { content: $fa-var-bar-chart-o; }
.#{$fa-css-prefix}-twitter-square:before { content: $fa-var-twitter-square; }
.#{$fa-css-prefix}-facebook-square:before { content: $fa-var-facebook-square; }
.#{$fa-css-prefix}-camera-retro:before { content: $fa-var-camera-retro; }
.#{$fa-css-prefix}-key:before { content: $fa-var-key; }
.#{$fa-css-prefix}-gears:before,
.#{$fa-css-prefix}-cogs:before { content: $fa-var-cogs; }
.#{$fa-css-prefix}-comments:before { content: $fa-var-comments; }
.#{$fa-css-prefix}-thumbs-o-up:before { content: $fa-var-thumbs-o-up; }
.#{$fa-css-prefix}-thumbs-o-down:before { content: $fa-var-thumbs-o-down; }
.#{$fa-css-prefix}-star-half:before { content: $fa-var-star-half; }
.#{$fa-css-prefix}-heart-o:before { content: $fa-var-heart-o; }
.#{$fa-css-prefix}-sign-out:before { content: $fa-var-sign-out; }
.#{$fa-css-prefix}-linkedin-square:before { content: $fa-var-linkedin-square; }
.#{$fa-css-prefix}-thumb-tack:before { content: $fa-var-thumb-tack; }
.#{$fa-css-prefix}-external-link:before { content: $fa-var-external-link; }
.#{$fa-css-prefix}-sign-in:before { content: $fa-var-sign-in; }
.#{$fa-css-prefix}-trophy:before { content: $fa-var-trophy; }
.#{$fa-css-prefix}-github-square:before { content: $fa-var-github-square; }
.#{$fa-css-prefix}-upload:before { content: $fa-var-upload; }
.#{$fa-css-prefix}-lemon-o:before { content: $fa-var-lemon-o; }
.#{$fa-css-prefix}-phone:before { content: $fa-var-phone; }
.#{$fa-css-prefix}-square-o:before { content: $fa-var-square-o; }
.#{$fa-css-prefix}-bookmark-o:before { content: $fa-var-bookmark-o; }
.#{$fa-css-prefix}-phone-square:before { content: $fa-var-phone-square; }
.#{$fa-css-prefix}-twitter:before { content: $fa-var-twitter; }
.#{$fa-css-prefix}-facebook:before { content: $fa-var-facebook; }
.#{$fa-css-prefix}-github:before { content: $fa-var-github; }
.#{$fa-css-prefix}-unlock:before { content: $fa-var-unlock; }
.#{$fa-css-prefix}-credit-card:before { content: $fa-var-credit-card; }
.#{$fa-css-prefix}-rss:before { content: $fa-var-rss; }
.#{$fa-css-prefix}-hdd-o:before { content: $fa-var-hdd-o; }
.#{$fa-css-prefix}-bullhorn:before { content: $fa-var-bullhorn; }
.#{$fa-css-prefix}-bell:before { content: $fa-var-bell; }
.#{$fa-css-prefix}-certificate:before { content: $fa-var-certificate; }
.#{$fa-css-prefix}-hand-o-right:before { content: $fa-var-hand-o-right; }
.#{$fa-css-prefix}-hand-o-left:before { content: $fa-var-hand-o-left; }
.#{$fa-css-prefix}-hand-o-up:before { content: $fa-var-hand-o-up; }
.#{$fa-css-prefix}-hand-o-down:before { content: $fa-var-hand-o-down; }
.#{$fa-css-prefix}-arrow-circle-left:before { content: $fa-var-arrow-circle-left; }
.#{$fa-css-prefix}-arrow-circle-right:before { content: $fa-var-arrow-circle-right; }
.#{$fa-css-prefix}-arrow-circle-up:before { content: $fa-var-arrow-circle-up; }
.#{$fa-css-prefix}-arrow-circle-down:before { content: $fa-var-arrow-circle-down; }
.#{$fa-css-prefix}-globe:before { content: $fa-var-globe; }
.#{$fa-css-prefix}-wrench:before { content: $fa-var-wrench; }
.#{$fa-css-prefix}-tasks:before { content: $fa-var-tasks; }
.#{$fa-css-prefix}-filter:before { content: $fa-var-filter; }
.#{$fa-css-prefix}-briefcase:before { content: $fa-var-briefcase; }
.#{$fa-css-prefix}-arrows-alt:before { content: $fa-var-arrows-alt; }
.#{$fa-css-prefix}-group:before,
.#{$fa-css-prefix}-users:before { content: $fa-var-users; }
.#{$fa-css-prefix}-chain:before,
.#{$fa-css-prefix}-link:before { content: $fa-var-link; }
.#{$fa-css-prefix}-cloud:before { content: $fa-var-cloud; }
.#{$fa-css-prefix}-flask:before { content: $fa-var-flask; }
.#{$fa-css-prefix}-cut:before,
.#{$fa-css-prefix}-scissors:before { content: $fa-var-scissors; }
.#{$fa-css-prefix}-copy:before,
.#{$fa-css-prefix}-files-o:before { content: $fa-var-files-o; }
.#{$fa-css-prefix}-paperclip:before { content: $fa-var-paperclip; }
.#{$fa-css-prefix}-save:before,
.#{$fa-css-prefix}-floppy-o:before { content: $fa-var-floppy-o; }
.#{$fa-css-prefix}-square:before { content: $fa-var-square; }
.#{$fa-css-prefix}-bars:before { content: $fa-var-bars; }
.#{$fa-css-prefix}-list-ul:before { content: $fa-var-list-ul; }
.#{$fa-css-prefix}-list-ol:before { content: $fa-var-list-ol; }
.#{$fa-css-prefix}-strikethrough:before { content: $fa-var-strikethrough; }
.#{$fa-css-prefix}-underline:before { content: $fa-var-underline; }
.#{$fa-css-prefix}-table:before { content: $fa-var-table; }
.#{$fa-css-prefix}-magic:before { content: $fa-var-magic; }
.#{$fa-css-prefix}-truck:before { content: $fa-var-truck; }
.#{$fa-css-prefix}-pinterest:before { content: $fa-var-pinterest; }
.#{$fa-css-prefix}-pinterest-square:before { content: $fa-var-pinterest-square; }
.#{$fa-css-prefix}-google-plus-square:before { content: $fa-var-google-plus-square; }
.#{$fa-css-prefix}-google-plus:before { content: $fa-var-google-plus; }
.#{$fa-css-prefix}-money:before { content: $fa-var-money; }
.#{$fa-css-prefix}-caret-down:before { content: $fa-var-caret-down; }
.#{$fa-css-prefix}-caret-up:before { content: $fa-var-caret-up; }
.#{$fa-css-prefix}-caret-left:before { content: $fa-var-caret-left; }
.#{$fa-css-prefix}-caret-right:before { content: $fa-var-caret-right; }
.#{$fa-css-prefix}-columns:before { content: $fa-var-columns; }
.#{$fa-css-prefix}-unsorted:before,
.#{$fa-css-prefix}-sort:before { content: $fa-var-sort; }
.#{$fa-css-prefix}-sort-down:before,
.#{$fa-css-prefix}-sort-asc:before { content: $fa-var-sort-asc; }
.#{$fa-css-prefix}-sort-up:before,
.#{$fa-css-prefix}-sort-desc:before { content: $fa-var-sort-desc; }
.#{$fa-css-prefix}-envelope:before { content: $fa-var-envelope; }
.#{$fa-css-prefix}-linkedin:before { content: $fa-var-linkedin; }
.#{$fa-css-prefix}-rotate-left:before,
.#{$fa-css-prefix}-undo:before { content: $fa-var-undo; }
.#{$fa-css-prefix}-legal:before,
.#{$fa-css-prefix}-gavel:before { content: $fa-var-gavel; }
.#{$fa-css-prefix}-dashboard:before,
.#{$fa-css-prefix}-tachometer:before { content: $fa-var-tachometer; }
.#{$fa-css-prefix}-comment-o:before { content: $fa-var-comment-o; }
.#{$fa-css-prefix}-comments-o:before { content: $fa-var-comments-o; }
.#{$fa-css-prefix}-flash:before,
.#{$fa-css-prefix}-bolt:before { content: $fa-var-bolt; }
.#{$fa-css-prefix}-sitemap:before { content: $fa-var-sitemap; }
.#{$fa-css-prefix}-umbrella:before { content: $fa-var-umbrella; }
.#{$fa-css-prefix}-paste:before,
.#{$fa-css-prefix}-clipboard:before { content: $fa-var-clipboard; }
.#{$fa-css-prefix}-lightbulb-o:before { content: $fa-var-lightbulb-o; }
.#{$fa-css-prefix}-exchange:before { content: $fa-var-exchange; }
.#{$fa-css-prefix}-cloud-download:before { content: $fa-var-cloud-download; }
.#{$fa-css-prefix}-cloud-upload:before { content: $fa-var-cloud-upload; }
.#{$fa-css-prefix}-user-md:before { content: $fa-var-user-md; }
.#{$fa-css-prefix}-stethoscope:before { content: $fa-var-stethoscope; }
.#{$fa-css-prefix}-suitcase:before { content: $fa-var-suitcase; }
.#{$fa-css-prefix}-bell-o:before { content: $fa-var-bell-o; }
.#{$fa-css-prefix}-coffee:before { content: $fa-var-coffee; }
.#{$fa-css-prefix}-cutlery:before { content: $fa-var-cutlery; }
.#{$fa-css-prefix}-file-text-o:before { content: $fa-var-file-text-o; }
.#{$fa-css-prefix}-building-o:before { content: $fa-var-building-o; }
.#{$fa-css-prefix}-hospital-o:before { content: $fa-var-hospital-o; }
.#{$fa-css-prefix}-ambulance:before { content: $fa-var-ambulance; }
.#{$fa-css-prefix}-medkit:before { content: $fa-var-medkit; }
.#{$fa-css-prefix}-fighter-jet:before { content: $fa-var-fighter-jet; }
.#{$fa-css-prefix}-beer:before { content: $fa-var-beer; }
.#{$fa-css-prefix}-h-square:before { content: $fa-var-h-square; }
.#{$fa-css-prefix}-plus-square:before { content: $fa-var-plus-square; }
.#{$fa-css-prefix}-angle-double-left:before { content: $fa-var-angle-double-left; }
.#{$fa-css-prefix}-angle-double-right:before { content: $fa-var-angle-double-right; }
.#{$fa-css-prefix}-angle-double-up:before { content: $fa-var-angle-double-up; }
.#{$fa-css-prefix}-angle-double-down:before { content: $fa-var-angle-double-down; }
.#{$fa-css-prefix}-angle-left:before { content: $fa-var-angle-left; }
.#{$fa-css-prefix}-angle-right:before { content: $fa-var-angle-right; }
.#{$fa-css-prefix}-angle-up:before { content: $fa-var-angle-up; }
.#{$fa-css-prefix}-angle-down:before { content: $fa-var-angle-down; }
.#{$fa-css-prefix}-desktop:before { content: $fa-var-desktop; }
.#{$fa-css-prefix}-laptop:before { content: $fa-var-laptop; }
.#{$fa-css-prefix}-tablet:before { content: $fa-var-tablet; }
.#{$fa-css-prefix}-mobile-phone:before,
.#{$fa-css-prefix}-mobile:before { content: $fa-var-mobile; }
.#{$fa-css-prefix}-circle-o:before { content: $fa-var-circle-o; }
.#{$fa-css-prefix}-quote-left:before { content: $fa-var-quote-left; }
.#{$fa-css-prefix}-quote-right:before { content: $fa-var-quote-right; }
.#{$fa-css-prefix}-spinner:before { content: $fa-var-spinner; }
.#{$fa-css-prefix}-circle:before { content: $fa-var-circle; }
.#{$fa-css-prefix}-mail-reply:before,
.#{$fa-css-prefix}-reply:before { content: $fa-var-reply; }
.#{$fa-css-prefix}-github-alt:before { content: $fa-var-github-alt; }
.#{$fa-css-prefix}-folder-o:before { content: $fa-var-folder-o; }
.#{$fa-css-prefix}-folder-open-o:before { content: $fa-var-folder-open-o; }
.#{$fa-css-prefix}-smile-o:before { content: $fa-var-smile-o; }
.#{$fa-css-prefix}-frown-o:before { content: $fa-var-frown-o; }
.#{$fa-css-prefix}-meh-o:before { content: $fa-var-meh-o; }
.#{$fa-css-prefix}-gamepad:before { content: $fa-var-gamepad; }
.#{$fa-css-prefix}-keyboard-o:before { content: $fa-var-keyboard-o; }
.#{$fa-css-prefix}-flag-o:before { content: $fa-var-flag-o; }
.#{$fa-css-prefix}-flag-checkered:before { content: $fa-var-flag-checkered; }
.#{$fa-css-prefix}-terminal:before { content: $fa-var-terminal; }
.#{$fa-css-prefix}-code:before { content: $fa-var-code; }
.#{$fa-css-prefix}-reply-all:before { content: $fa-var-reply-all; }
.#{$fa-css-prefix}-mail-reply-all:before { content: $fa-var-mail-reply-all; }
.#{$fa-css-prefix}-star-half-empty:before,
.#{$fa-css-prefix}-star-half-full:before,
.#{$fa-css-prefix}-star-half-o:before { content: $fa-var-star-half-o; }
.#{$fa-css-prefix}-location-arrow:before { content: $fa-var-location-arrow; }
.#{$fa-css-prefix}-crop:before { content: $fa-var-crop; }
.#{$fa-css-prefix}-code-fork:before { content: $fa-var-code-fork; }
.#{$fa-css-prefix}-unlink:before,
.#{$fa-css-prefix}-chain-broken:before { content: $fa-var-chain-broken; }
.#{$fa-css-prefix}-question:before { content: $fa-var-question; }
.#{$fa-css-prefix}-info:before { content: $fa-var-info; }
.#{$fa-css-prefix}-exclamation:before { content: $fa-var-exclamation; }
.#{$fa-css-prefix}-superscript:before { content: $fa-var-superscript; }
.#{$fa-css-prefix}-subscript:before { content: $fa-var-subscript; }
.#{$fa-css-prefix}-eraser:before { content: $fa-var-eraser; }
.#{$fa-css-prefix}-puzzle-piece:before { content: $fa-var-puzzle-piece; }
.#{$fa-css-prefix}-microphone:before { content: $fa-var-microphone; }
.#{$fa-css-prefix}-microphone-slash:before { content: $fa-var-microphone-slash; }
.#{$fa-css-prefix}-shield:before { content: $fa-var-shield; }
.#{$fa-css-prefix}-calendar-o:before { content: $fa-var-calendar-o; }
.#{$fa-css-prefix}-fire-extinguisher:before { content: $fa-var-fire-extinguisher; }
.#{$fa-css-prefix}-rocket:before { content: $fa-var-rocket; }
.#{$fa-css-prefix}-maxcdn:before { content: $fa-var-maxcdn; }
.#{$fa-css-prefix}-chevron-circle-left:before { content: $fa-var-chevron-circle-left; }
.#{$fa-css-prefix}-chevron-circle-right:before { content: $fa-var-chevron-circle-right; }
.#{$fa-css-prefix}-chevron-circle-up:before { content: $fa-var-chevron-circle-up; }
.#{$fa-css-prefix}-chevron-circle-down:before { content: $fa-var-chevron-circle-down; }
.#{$fa-css-prefix}-html5:before { content: $fa-var-html5; }
.#{$fa-css-prefix}-css3:before { content: $fa-var-css3; }
.#{$fa-css-prefix}-anchor:before { content: $fa-var-anchor; }
.#{$fa-css-prefix}-unlock-alt:before { content: $fa-var-unlock-alt; }
.#{$fa-css-prefix}-bullseye:before { content: $fa-var-bullseye; }
.#{$fa-css-prefix}-ellipsis-h:before { content: $fa-var-ellipsis-h; }
.#{$fa-css-prefix}-ellipsis-v:before { content: $fa-var-ellipsis-v; }
.#{$fa-css-prefix}-rss-square:before { content: $fa-var-rss-square; }
.#{$fa-css-prefix}-play-circle:before { content: $fa-var-play-circle; }
.#{$fa-css-prefix}-ticket:before { content: $fa-var-ticket; }
.#{$fa-css-prefix}-minus-square:before { content: $fa-var-minus-square; }
.#{$fa-css-prefix}-minus-square-o:before { content: $fa-var-minus-square-o; }
.#{$fa-css-prefix}-level-up:before { content: $fa-var-level-up; }
.#{$fa-css-prefix}-level-down:before { content: $fa-var-level-down; }
.#{$fa-css-prefix}-check-square:before { content: $fa-var-check-square; }
.#{$fa-css-prefix}-pencil-square:before { content: $fa-var-pencil-square; }
.#{$fa-css-prefix}-external-link-square:before { content: $fa-var-external-link-square; }
.#{$fa-css-prefix}-share-square:before { content: $fa-var-share-square; }
.#{$fa-css-prefix}-compass:before { content: $fa-var-compass; }
.#{$fa-css-prefix}-toggle-down:before,
.#{$fa-css-prefix}-caret-square-o-down:before { content: $fa-var-caret-square-o-down; }
.#{$fa-css-prefix}-toggle-up:before,
.#{$fa-css-prefix}-caret-square-o-up:before { content: $fa-var-caret-square-o-up; }
.#{$fa-css-prefix}-toggle-right:before,
.#{$fa-css-prefix}-caret-square-o-right:before { content: $fa-var-caret-square-o-right; }
.#{$fa-css-prefix}-euro:before,
.#{$fa-css-prefix}-eur:before { content: $fa-var-eur; }
.#{$fa-css-prefix}-gbp:before { content: $fa-var-gbp; }
.#{$fa-css-prefix}-dollar:before,
.#{$fa-css-prefix}-usd:before { content: $fa-var-usd; }
.#{$fa-css-prefix}-rupee:before,
.#{$fa-css-prefix}-inr:before { content: $fa-var-inr; }
.#{$fa-css-prefix}-cny:before,
.#{$fa-css-prefix}-rmb:before,
.#{$fa-css-prefix}-yen:before,
.#{$fa-css-prefix}-jpy:before { content: $fa-var-jpy; }
.#{$fa-css-prefix}-ruble:before,
.#{$fa-css-prefix}-rouble:before,
.#{$fa-css-prefix}-rub:before { content: $fa-var-rub; }
.#{$fa-css-prefix}-won:before,
.#{$fa-css-prefix}-krw:before { content: $fa-var-krw; }
.#{$fa-css-prefix}-bitcoin:before,
.#{$fa-css-prefix}-btc:before { content: $fa-var-btc; }
.#{$fa-css-prefix}-file:before { content: $fa-var-file; }
.#{$fa-css-prefix}-file-text:before { content: $fa-var-file-text; }
.#{$fa-css-prefix}-sort-alpha-asc:before { content: $fa-var-sort-alpha-asc; }
.#{$fa-css-prefix}-sort-alpha-desc:before { content: $fa-var-sort-alpha-desc; }
.#{$fa-css-prefix}-sort-amount-asc:before { content: $fa-var-sort-amount-asc; }
.#{$fa-css-prefix}-sort-amount-desc:before { content: $fa-var-sort-amount-desc; }
.#{$fa-css-prefix}-sort-numeric-asc:before { content: $fa-var-sort-numeric-asc; }
.#{$fa-css-prefix}-sort-numeric-desc:before { content: $fa-var-sort-numeric-desc; }
.#{$fa-css-prefix}-thumbs-up:before { content: $fa-var-thumbs-up; }
.#{$fa-css-prefix}-thumbs-down:before { content: $fa-var-thumbs-down; }
.#{$fa-css-prefix}-youtube-square:before { content: $fa-var-youtube-square; }
.#{$fa-css-prefix}-youtube:before { content: $fa-var-youtube; }
.#{$fa-css-prefix}-xing:before { content: $fa-var-xing; }
.#{$fa-css-prefix}-xing-square:before { content: $fa-var-xing-square; }
.#{$fa-css-prefix}-youtube-play:before { content: $fa-var-youtube-play; }
.#{$fa-css-prefix}-dropbox:before { content: $fa-var-dropbox; }
.#{$fa-css-prefix}-stack-overflow:before { content: $fa-var-stack-overflow; }
.#{$fa-css-prefix}-instagram:before { content: $fa-var-instagram; }
.#{$fa-css-prefix}-flickr:before { content: $fa-var-flickr; }
.#{$fa-css-prefix}-adn:before { content: $fa-var-adn; }
.#{$fa-css-prefix}-bitbucket:before { content: $fa-var-bitbucket; }
.#{$fa-css-prefix}-bitbucket-square:before { content: $fa-var-bitbucket-square; }
.#{$fa-css-prefix}-tumblr:before { content: $fa-var-tumblr; }
.#{$fa-css-prefix}-tumblr-square:before { content: $fa-var-tumblr-square; }
.#{$fa-css-prefix}-long-arrow-down:before { content: $fa-var-long-arrow-down; }
.#{$fa-css-prefix}-long-arrow-up:before { content: $fa-var-long-arrow-up; }
.#{$fa-css-prefix}-long-arrow-left:before { content: $fa-var-long-arrow-left; }
.#{$fa-css-prefix}-long-arrow-right:before { content: $fa-var-long-arrow-right; }
.#{$fa-css-prefix}-apple:before { content: $fa-var-apple; }
.#{$fa-css-prefix}-windows:before { content: $fa-var-windows; }
.#{$fa-css-prefix}-android:before { content: $fa-var-android; }
.#{$fa-css-prefix}-linux:before { content: $fa-var-linux; }
.#{$fa-css-prefix}-dribbble:before { content: $fa-var-dribbble; }
.#{$fa-css-prefix}-skype:before { content: $fa-var-skype; }
.#{$fa-css-prefix}-foursquare:before { content: $fa-var-foursquare; }
.#{$fa-css-prefix}-trello:before { content: $fa-var-trello; }
.#{$fa-css-prefix}-female:before { content: $fa-var-female; }
.#{$fa-css-prefix}-male:before { content: $fa-var-male; }
.#{$fa-css-prefix}-gittip:before { content: $fa-var-gittip; }
.#{$fa-css-prefix}-sun-o:before { content: $fa-var-sun-o; }
.#{$fa-css-prefix}-moon-o:before { content: $fa-var-moon-o; }
.#{$fa-css-prefix}-archive:before { content: $fa-var-archive; }
.#{$fa-css-prefix}-bug:before { content: $fa-var-bug; }
.#{$fa-css-prefix}-vk:before { content: $fa-var-vk; }
.#{$fa-css-prefix}-weibo:before { content: $fa-var-weibo; }
.#{$fa-css-prefix}-renren:before { content: $fa-var-renren; }
.#{$fa-css-prefix}-pagelines:before { content: $fa-var-pagelines; }
.#{$fa-css-prefix}-stack-exchange:before { content: $fa-var-stack-exchange; }
.#{$fa-css-prefix}-arrow-circle-o-right:before { content: $fa-var-arrow-circle-o-right; }
.#{$fa-css-prefix}-arrow-circle-o-left:before { content: $fa-var-arrow-circle-o-left; }
.#{$fa-css-prefix}-toggle-left:before,
.#{$fa-css-prefix}-caret-square-o-left:before { content: $fa-var-caret-square-o-left; }
.#{$fa-css-prefix}-dot-circle-o:before { content: $fa-var-dot-circle-o; }
.#{$fa-css-prefix}-wheelchair:before { content: $fa-var-wheelchair; }
.#{$fa-css-prefix}-vimeo-square:before { content: $fa-var-vimeo-square; }
.#{$fa-css-prefix}-turkish-lira:before,
.#{$fa-css-prefix}-try:before { content: $fa-var-try; }
.#{$fa-css-prefix}-plus-square-o:before { content: $fa-var-plus-square-o; }
