.themes__builder{
	#menu-themes-builder{
		margin: 0;
		background-color: #F7F7F7;
		ul{
			margin: 0;
			box-shadow: 0 1px 5px 0 rgba(0,0,0,0.15);
		}
		span{
			cursor: pointer;
			font-size: 12px;
			font-weight: bold;
		}
		& > li{
			& > span{				
				padding: 10px 15px;
			}
			&:hover{
				& > span{
					color: $color-base;
				}
				& > ul{
					margin: 0;
					left: 0;
				}
			}
			& > ul{
				background-color: #F7F7F7;
				& > li{		
					position: relative;
					span{
						padding: 4px 15px;	
					}
					&:hover{
						& > span{
							color: $color-base;
						}
					}
					& > ul{
						background-color: #F7F7F7;	
						& > li{
							& > span{
								display: block;
							}
							&:hover{
								& > span{
									color: $color-base;
								}
							}
						}
					}
				}
			}
		}
	}
}